// app/components/AboutPopup.tsx

import React, { useState } from "react";

interface Props {
  onCloseClick: () => void;
  supportPopupFunc: () => void;
  proUser: boolean;
}

export const AboutPopup: React.FC<Props> = ({ onCloseClick, supportPopupFunc, proUser }) => {
  return (
    <>
      <div className={"popup-wrap"}>
        <div className="popup-container">
          <div className="popup-content">
            <div className="popup-logo">
              <img src="/icons/about-logo.svg" alt="fontcert" width={48} height={48} />
            </div>
            <div className="popup-title-sec">
              <h3>A Stage for Remarkable Fonts</h3>
            </div>
            <div className="popup-description">
              <p>
                Fontcert features the finest typography and its 'in use' in a simple way,
                streamlining your font hunt to save you from drowning in a sea of options.
              </p>
              <ul>
                <li>
                  <div className="list-icon">
                    <img src="/icons/about-1.svg" alt="fontcert" width={22} height={22} />
                  </div>
                  <p>
                    Explore quality fonts, with their in-uses, that helps you
                    get a proper taste before downloading and installing.
                  </p>
                </li>
                <li>
                  <div className="list-icon">
                    <img src="/icons/about-2.svg" alt="fontcert" width={22} height={22} />
                  </div>
                  <p>
                    Curate your favorites by bookmarking standout fonts
                    and their inspiring in-use examples.
                  </p>
                </li>
                <li>
                  <div className="list-icon">
                    <img src="/icons/about-3.svg" alt="fontcert" width={22} height={22} />
                  </div>
                  <p>
                    Curate creative inspiration from the web and save it to your collection for easy reference.<span className="soon">Coming Soon</span>
                  </p>
                </li>
                <li>
                  <div className="list-icon">
                    <img src="/icons/about-4.svg" alt="fontcert" width={22} height={22} />
                  </div>
                  <p>
                    Join creative challenges, give/get feedback, observe others' works, build portfolio.<span className="soon">Coming Soon</span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="popup-bottom">
              <button type="button" onClick={() => onCloseClick()} className="about-pop-btn">
                Explore Fontcert
              </button>
              {!proUser && (
                <button type="button" onClick={() => supportPopupFunc()} className="about-pop-btn light-btn">
                  <span>Become a supporter</span><img src="/icons/about-pro.svg" alt="support fontcert" width={14} height={14} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
