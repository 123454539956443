// app/components/Header.tsx
import { Link, useLocation } from "@remix-run/react";
import React, { useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { AboutPopup } from "./AboutPopup";
import MusicPlayer from "./MusicPlayer";
import { LoginPopup } from "./LoginPopup";
import { checkConfiguration, isAuthenticated, logoutUser } from "~/features/AuthHelper";
import { logout } from "~/features/authSlice";
import { hideLoginPopup, showLoginPopup } from "~/features/loginPopupSlice";
import { SupportPopup } from "./SupportPopup";
import { hideProPopup, showProPopup } from "~/features/proPopupSlice";
import { ProPopup } from "./ProPopup";

interface HeaderProps {
  user: any;
  proUser: boolean;
}

const Header: React.FC<HeaderProps> = ({ user, proUser }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [userDropdown, setUserDropdown] = useState(false);
  const [showSupportPopup, setShowSupportPopup] = useState(false);

  const showLogin = useSelector((state: any) => state.loginPopup.show);

  useEffect(() => {
    if (!localStorage.getItem("popupShown")) {
      // if no localstorage key found
      setTimeout(() => {
        setShowAbout(true);
        localStorage.setItem("popupShown", "true");
      }, 1000);
    }
  }, []);

  const toggleDropdown = () => {
    setShowMenu(!showMenu);
    setUserDropdown(false);
  };

  const location = useLocation();
  const dispatch = useDispatch();

  const authenticated = isAuthenticated();
  useEffect(() => {
    setShowLoading(true)
    if (isAuthenticated !== undefined) {
      setShowLoading(false)
    }
    setLoggedIn(authenticated);
    // update the configuration
    checkConfiguration(dispatch);
  }, [isAuthenticated(), location]);

  const handleLogout = () => {
    logoutUser();
    dispatch(logout());
    setUserDropdown(false);
    toast.success("Logged out successfully!");
    // update the configuration
    checkConfiguration(dispatch);
  };

  const handleMyAccount = () => {
    setShowMenu(false);
    window.location.href = "/my-account";
  };

  const proPopupShow = useSelector((state: any) => state.proPopup.show);

  return (
    <>
      {showLoading ? <div className="page-loader">
        <img src="/images/logo.svg" alt="fontcert" width={139} height={28} />
        <span><i className="loader"></i></span>
      </div> : null}
      <header className="header">
        <nav className="nav">
          <Link to="/" className="logo">
            <img src="/images/logo.svg" alt="fontcert" width={139} height={28} />
          </Link>
          <div className="nav_items">
            <Link
              className="featured-nav"
              target="_blank"
              title="get featured on Fontcert"
              rel="noreferrer nofollow"
              to={`https://forms.gle/iRXmDArDCvSsN8e19`}
            >
              Get Featured
            </Link>
            <MusicPlayer />
            {loggedIn ? (
              <>
                <div className="logged-user">
                  {user && proUser && <span className="pro-badge">
                    <img
                      src={"/icons/pro-white.svg"}
                      alt="pro" width={14} height={14} />
                  </span>}
                  <div className="user-photo" onClick={() => { setUserDropdown(!userDropdown), setShowMenu(false) }} title="User Info">
                    {user && user.photo_url ? (
                      <img
                        className="user-photo"
                        src={user ? user.photo_url : "/icons/icon-logo.svg"}
                        alt="user" width={36} height={36} />
                    ) : (
                      <div className="user-letter">
                        {user && user.first_name ? user.first_name.charAt(0) : "U"}
                      </div>
                    )}
                  </div>

                  {userDropdown && (
                    <ul className="dropdown-menu">
                      <li>
                        <div className="user-details">
                          <span>{user ? user.display_name : "User name"}</span>
                          <span className="user-email">{user ? user.email : "User email"}</span>
                        </div>
                      </li>
                      {/* if not pro */}
                      {user && !proUser &&
                        <li>
                          <button
                            type="button"
                            onClick={() => {
                              dispatch(showProPopup()),
                                setUserDropdown(false);
                            }}
                          >
                            Go Pro
                          </button>
                        </li>
                      }
                      <li>
                        <button
                          type="button"
                          onClick={() => {
                            handleMyAccount();
                          }}
                        >
                          My Account
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={() => {
                            handleLogout();
                          }}
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </>
            ) : (
              <button
                type="button"
                className="login-btn"
                onClick={() => {
                  dispatch(showLoginPopup()), setShowMenu(false);
                }}
              >
                Login <span>/ Signup</span>
              </button>
            )}
            <div className="dropdown">
              <button
                type="button"
                className="icon_button"
                onClick={() => toggleDropdown()}
                title="Menu"
              >
                <img
                  src="/icons/icon-menu.svg"
                  className="header_icon"
                  alt="Menu Button"
                  width={16}
                  height={14}
                />
              </button>
              {showMenu && (
                <ul className="dropdown-menu">
                  {/* <li>
                    <button
                      type="button"
                      onClick={() => {
                        setShowAbout(true), setShowMenu(false), setUserDropdown(false);
                      }}
                    >
                      About
                    </button>
                  </li> */}
                  <li>
                    <Link
                      to={`https://forms.gle/iRXmDArDCvSsN8e19`}
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      Get Featured
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/">Privacy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms</Link>
                  </li> */}
                </ul>
              )}
            </div>
          </div>
        </nav>
      </header>
      <div className="header_spacer"></div>
      {/* drodown overlay */}
      {(showMenu || userDropdown) && (
        <div
          className="dropdown-overlay"
          onClick={() => { setShowMenu(false), setUserDropdown(false) }}
        ></div>
      )}
      {showAbout && <AboutPopup proUser={user && proUser} onCloseClick={() => setShowAbout(false)} supportPopupFunc={() => { setShowAbout(false), setShowSupportPopup(true) }} />}
      {showLogin && <LoginPopup onCloseClick={() => dispatch(hideLoginPopup())} />}
      {showSupportPopup && <SupportPopup onCloseClick={() => setShowSupportPopup(false)} />}
      {proPopupShow && (<ProPopup onCloseClick={() => dispatch(hideProPopup())} />)}
    </>
  );
};

export default Header;
