// app/components/LoginPopup.tsx

import React, { useEffect, useRef, useState } from "react";
import {
  signInWithGoogle,
  signInWithEmailPassword,
  registerWithEmailAndPassword,
  isAuthenticated,
} from "~/features/AuthHelper";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';

interface Props {
  onCloseClick: () => void;
}

export const LoginPopup: React.FC<Props> = ({ onCloseClick }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("register");
  const [formValid, setFormValid] = useState(false);
  const [registrationDone, setRegistrationDone] = useState(false);

  const [loginMethod, setLoginMethod] = useState("email");
  const [firbaseError, setFirebaseError] = useState("");



  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (type === 'register' && nameRef.current) {
      nameRef.current.focus();
    } else {
      emailRef.current?.focus();
    }
  }, [type]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setEmail(e.target.value);
    if (validateEmail(e.target.value)) {
      setError("");
      if (password.length >= 6) {
        setFormValid(true);
        setError("");
      } else if (password !== "" && password.length < 6) {
        setError("Password must be at least 6 characters long");
        setFormValid(false);
      }
    } else {
      setError("Please enter a valid email address");
      setFormValid(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPassword(e.target.value);
    if (e.target.value.length >= 6) {
      setError("");
      if (validateEmail(email)) {
        setError("");
        setFormValid(true);
      } else {
        setError("Please enter a valid email address");
        setFormValid(false);
      }
    } else {
      setError("Password must be at least 6 characters long");
      setFormValid(false);
    }
  };

  const authenticated = isAuthenticated();

  useEffect(() => {
    if (authenticated) {
      // authenticated, close the popup, remove the loading state
      setLoading(false);
      onCloseClick();
    }
  }, [authenticated]);

  const handleSignInWithGoogle = async () => {
    setLoginMethod('google');
    setFirebaseError("");
    setLoading(true); // Set loading state to true when sign-in process starts
    try {
      await signInWithGoogle(dispatch);
      // Sign-in successful, perform any additional actions if needed
      toast.success("Logged in successfully!");
    } catch (error: any) {
      // Handle sign-in error
      let errorMessage = "An error occurred during sign-in.";

      // Customize error message based on Firebase error code
      switch (error.code) {
        case "auth/invalid-credential":
          errorMessage = "Invalid email address or password.";
          break;
        case "auth/user-disabled":
          errorMessage = "User account is disabled.";
          break;
        default:
          errorMessage = "An error occurred during sign-in.";
      }
      setLoading(false);
      setFirebaseError(errorMessage);
    }
  };

  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoginMethod('email');
    setFirebaseError("");
    setLoading(true); // Set loading state to true when sign-in process starts
    try {
      await signInWithEmailPassword(dispatch, email, password);
      // Sign-in successful, perform any additional actions if needed
      toast.success("Logged in successfully!");

    } catch (error: any) {
      // Handle sign-in error
      let errorMessage = "An error occurred during sign-in.";

      // Customize error message based on Firebase error code
      switch (error.code) {
        case "auth/invalid-credential":
          errorMessage = "Invalid email address or password.";
          break;
        case "auth/user-disabled":
          errorMessage = "User account is disabled.";
          break;
        default:
          errorMessage = "An error occurred during sign-in.";
      }
      setLoading(false);
      setFirebaseError(errorMessage);
      // throw error;
    }
  };

  const handleRegister = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    setFirebaseError("");
    setLoginMethod('email');
    try {
      // Call registerWithEmailAndPassword function with form values
      await registerWithEmailAndPassword(
        email,
        password,
        displayName || "Name"
      );
      // Registration successful.
      setLoading(false);
      setRegistrationDone(true);
      toast.success("New account created, please login to continue.");
    } catch (error: any) {
      // Handle sign-in error
      let errorMessage = "An error occurred during sign-in.";
      // Customize error message based on Firebase error code
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "Email already registered, please login instead.";
          break;
        case "auth/user-disabled":
          errorMessage = "User account is disabled.";
          break;
        default:
          errorMessage = "An error occurred during sign-in.";
      }
      setLoading(false);
      setFirebaseError(errorMessage);
      // throw error;
    }
  };

  const typeChange = (
    type: React.SetStateAction<string>,
    event: { preventDefault: () => void } | undefined
  ) => {
    event?.preventDefault();
    setType(type);
    setError("");
    setFormValid(false);
    setEmail("");
    setPassword("");
    setFirebaseError("");
    setRegistrationDone(false);
    setLoading(false);
  };

  return (
    <div className={"popup-wrap login-popup"}>
      <div className="login-pop-close" onClick={onCloseClick}></div>
      <div className="popup-container">
        <div className="popup-close-btn" onClick={onCloseClick} title="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 5L4.99998 19M5.00001 5L19 19"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="login-popup-wrap">
          <div className="login-popup-left">
            <div className="login-popup-logo">
              <img
                src="/icons/logo-icon.svg"
                width={40}
                height={40}
                alt="fontcert"
              />
            </div>
            <div className="popup-form">
              <h3>
                {type === "login" ? "Welcome back!" : "Create an account"}
              </h3>
              <div className="popup-form-items">
                {type === "register" && (
                  <div className="popup-form-item">
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Name"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      required
                      ref={nameRef}
                    />
                  </div>
                )}
                <div className="popup-form-item">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    ref={emailRef}
                  />
                </div>
                <div className="popup-form-item">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />
                </div>
                {error && <div className="error-text">{error}</div>}
              </div>
              <div className="popup-form-bottom">
                <p className="terms-text">
                  By {type === "login" ? "logging in" : "registering"}, you
                  accept our <a href="#">Terms</a>
                </p>
                <div className="popup-form-btn">
                  <button
                    type="button"
                    onClick={type === "login" ? handleSignIn : handleRegister}
                    disabled={!formValid}
                    className={loginMethod === 'email' && loading ? "loading" : ""}
                  >
                    <img src="/icons/icon-arrow.svg" alt="fontcert" width={18} height={18} />
                    <span>
                      {type === "login" ? "Login to" : "Join"} Fontcert
                    </span>
                  </button>
                </div>
                <div className="popup-or-text">
                  <span>Or</span>
                </div>
                <div className="popup-form-btn popup-social-btn">
                  <button type="button" onClick={handleSignInWithGoogle} className={loginMethod === 'google' && loading ? "loading" : ""}>
                    <img src="/icons/icon-google.svg" alt="fontcert" width={18} height={18} />
                    <span>Continue with Google</span>
                  </button>
                </div>
                <p>
                  {type === "login" ? (
                    <>
                      New to Fontcert?{" "}
                      <a href="#!" onClick={(e) => typeChange("register", e)}>
                        Register for free
                      </a>
                    </>
                  ) : (
                    <>
                      Already have an account?{" "}
                      <a href="#!" onClick={(e) => typeChange("login", e)}>
                        Login now
                      </a>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            className="login-popup-right"
            style={{ backgroundImage: 'url("/images/login-image.png")' }}
          >
            <div className="popup-right-user">
              <img
                src="/images/user.png"
                alt="fontcert"
                width={32}
                height={32}
              />
            </div>
          </div>
          <div className="popup-message">
            {firbaseError !== "" && <div className="message-text error">{firbaseError} <span onClick={() => { setFirebaseError("") }}>x</span></div>}
            {registrationDone && <div className="message-text success">Successfully registered, <a href="#!" onClick={(e) => typeChange("login", e)}>
              click here to login.
            </a></div>}
          </div>
        </div>
      </div>
    </div>
  );
};
