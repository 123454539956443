// stripeFunctions.ts
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import toast from 'react-hot-toast';
import { showLoginPopup } from './loginPopupSlice';
axios.defaults.withCredentials = true;

let debug = false;
const stripePromise = loadStripe(debug ? 'pk_test_51O6y3aSCcg7V3EbmxJAEtFC0LXX7x4pjTxLI4eKwujbne8PBFCzDjiM7t8smUOHRkWD0TcoJUNVURELMiwAfEAwl00OSm6Uunm' : 'pk_live_51O6y3aSCcg7V3Ebmlpm4u6hcgzzjpB9czaSpV1dUiiV0zp8xwwKissnFG91jfUsYAiZuD3aSiU8STAuze4qrYV5z0047s1hICT'); // replace with your Stripe public keys

const offerings: any = {
  PRO: {
    1: { product_key: "1m-pro", name: "Monthly", terms: 1, actual_price: 10, offer_price: 10, price: 10, discount_percentage: 0, currency: "USD" },
    3: { product_key: "3m-pro", name: "Quarterly", terms: 3, actual_price: 21, offer_price: 15, price: 45, discount_percentage: 30, currency: "USD" },
    12: { product_key: "12m-pro", name: "Yearly", terms: 12, actual_price: 21, offer_price: 12, price: 144, discount_percentage: 43, currency: "USD" },
    0: { product_key: "believer-plan", name: "Believer", terms: 0, actual_price: 50, offer_price: 50, price: 50, discount_percentage: 0, currency: "USD" }
  },
}

export const subscribeStripePlan = async (dataset: any, isAuthenticated: boolean, dispatch: any) => {
  if (!isAuthenticated) {
    toast("Kindly login to continue", { icon: '🔒' });

    // Show login popup
    dispatch(showLoginPopup())
    return;
  }

  let term = parseInt(dataset.term);
  let entitlement = dataset.entitlement;
  let product = offerings[entitlement][term];
  if (product.price === 0) {
    // Show a message that it's free
    return;
  }

  let sessionID = await createCheckoutSession(product);
  await stripeCheckout(sessionID);
};

export const createCheckoutSession = async (product: any) => {
  let product_key = product.product_key;
  let debug = false;
  let url = debug ? `http://localhost:5510/stripe/create-checkout-session/` : `https://backend.fontcert.com/stripe/create-checkout-session/`;
  let data = new FormData();
  data.append("product_key", product_key);
  let currentURL = new URL(window.location.href);
  data.append("base_url", `${currentURL.origin}`);
  // if (discountCode) {
  //   data.append("coupon_id", discountCode);
  // }

  // if (currencyCode) {
  //   data.append("currency_code", currencyCode);
  // }

  data.append("success_url", `${currentURL.origin}/payment-processing/?paymentSource=stripe`);
  data.append("cancel_url", `${currentURL.origin}/`);

  try {
    const response = await axios.post(url, data);
    toast('Redirecting to payment gateway');
    return response.data;
  } catch (error) {
    //  console.error('Error occurred while making the POST request:', error);
    toast.error('Error! Please try again later');
    throw error; // Re-throw the error after logging it
  }
};

export const stripeCheckout = async (sessionID: string) => {
  const stripe = await stripePromise;
  if (!stripe) {
    throw new Error('Stripe is not initialized');
  }
  await stripe.redirectToCheckout({
    sessionId: sessionID
  });
};