import React, { useEffect, useState } from "react";

import YouTube from "react-youtube";

type NullableString = string | null;

const MusicPlayer: React.FC = () => {
  const [musicPlaying, setMusicPlaying] = useState(false);
  const [musicBoxVisible, setmMusicBoxVisible] = useState(false);
  const [youtubeId, setYoutubeId] = useState<NullableString>(null);

  useEffect(() => {
    setYoutubeId(
      window.localStorage.getItem("youtubeId") !== null
        ? window.localStorage.getItem("youtubeId")
        : null
    );
    setMusicPlaying(
      window.localStorage.getItem("youtubeId") !== null ? true : false
    );
  }, []);

  const musicBoxToggle = () => {
    setmMusicBoxVisible(!musicBoxVisible);
  };
  const musicBoxClose = () => {
    setmMusicBoxVisible(false);
  };

  const toggleMusic = (id: string) => {
    if (youtubeId === id) {
      // means current music is already playing
      setMusicPlaying(false); // stop the music
      setYoutubeId(null);
      window.localStorage.removeItem("youtubeId");
    } else {
      window.localStorage.setItem("youtubeId", id);
      setYoutubeId(id);
      setMusicPlaying(true);
    }
  };

  const onPlayerReady = (event: {
    target: {
      pauseVideo: () => void;
      playVideo: () => void;
      unMute: () => void;
    };
  }) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
    // event.target.unMute()
    if (musicPlaying) {
      event.target.playVideo();
      event.target.unMute();
    }
  };

  const opts = {
    height: "0",
    width: "0",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      mute: 1,
    },
  };

  return (
    <>
      {youtubeId !== null ? (
        <div className="yt-iframe">
          <YouTube videoId={youtubeId} opts={opts} onReady={onPlayerReady} />
        </div>
      ) : null}

      {musicBoxVisible && (
        <div
          onClick={() => musicBoxClose()}
          className="music-player-close"
        ></div>
      )}
      <div className={`music-player ${musicBoxVisible && "active"}`}>
        <div
          title="toggle music"
          className={`music-toggler ${musicPlaying && "playing"}`}
          onClick={() => musicBoxToggle()}
        >
          {musicPlaying ? (
            <img
              src="/images/music/playing.gif"
              alt="icon"
              className="music-icon music-gif"
              width={16} height={16}
            />
          ) : (
            <img
              src="/images/music/headphone.svg"
              alt="icon"
              className="music-icon"
              width={16} height={16}
            />
          )}
        </div>

        <div className="music-toggle-box">
          <span
            title="toggle this music"
            onClick={() => toggleMusic("FxAgAyZYXJ8")}
            className={`music-item ${youtubeId === "FxAgAyZYXJ8" && "active"}`}
          >
            <img src="/images/music/forest.svg" alt="icon" width={16} height={16} />
          </span>
          <span
            title="toggle this music"
            onClick={() => toggleMusic("kvBXhc4qePw")}
            className={`music-item ${youtubeId === "kvBXhc4qePw" && "active"}`}
          >
            <img src="/images/music/rain.svg" alt="icon" width={16} height={16} />
          </span>
          <span
            title="toggle this music"
            onClick={() => toggleMusic("jS7UIks7wv8")}
            className={`music-item ${youtubeId === "jS7UIks7wv8" && "active"}`}
          >
            <img src="/images/music/office.svg" alt="icon" width={16} height={16} />
          </span>
          <span
            title="toggle this music"
            onClick={() => toggleMusic("gGOpElxqlQw")}
            className={`music-item ${youtubeId === "gGOpElxqlQw" && "active"}`}
          >
            <img src="/images/music/music.svg" alt="icon" width={16} height={16} />
          </span>
        </div>
      </div>
    </>
  );
};

export default MusicPlayer;
