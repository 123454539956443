import { useEffect } from "react";

const PopupCarousel = () => {
    useEffect(() => {
        const items = document.querySelectorAll('.popup-carousel-item');
        const numItems = items.length;
        const animationDuration = 16; // Duration in seconds

        items.forEach((item, index) => {
            const delay = (animationDuration / numItems) * index;
            (item as HTMLElement).style.animationDelay = `${delay}s`;
        });
    }, []);

    return (
        <div className="popup-carousel">
            <div className="popup-carousel-item">
                <img src="/images/support-1.png" alt="fontcert" width={310} height={375} />
            </div>
            <div className="popup-carousel-item">
                <img src="/images/support-2.png" alt="fontcert" width={310} height={375} />
            </div>
            <div className="popup-carousel-item">
                <img src="/images/support-3.png" alt="fontcert" width={310} height={375} />
            </div>
            <div className="popup-carousel-item">
                <img src="/images/support-4.png" alt="fontcert" width={310} height={375} />
            </div>
        </div>
    );
};

export default PopupCarousel;
