import React, { useState } from 'react';
import { subscribeStripePlan } from '~/features/stripeFunctions';
import { useDispatch } from 'react-redux';

interface PaywallButtonProps {
    entitlement: string;
    term: number;
    isAuth: boolean;
    className?: string;
    children: React.ReactNode;
}

const PaywallButton: React.FC<PaywallButtonProps> = ({ entitlement, term, isAuth, className, children }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);
        try {
            await subscribeStripePlan({ entitlement, term }, isAuth, dispatch);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            type="button"
            className={`${className} ${isLoading ? 'loading' : ''}`}
            onClick={handleClick}
        >
            {children}
        </button>
    );
};

export default PaywallButton;