// app/components/ProPopup.tsx
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isAuthenticated } from "~/features/AuthHelper";
import PaywallButton from "./PaywallButton";

interface Props {
    onCloseClick: () => void;
}

export const ProPopup: React.FC<Props> = ({ onCloseClick }) => {
    const [stripeError, setStripeError] = useState("");
    const [isAuth, setIsAuth] = useState(false);

    const authenticated = isAuthenticated();
    useEffect(() => {
        setIsAuth(authenticated);
    }, [isAuthenticated()]);

    return (
        <div className={"popup-wrap login-popup pro-popup"}>
            <div className="login-pop-close" onClick={onCloseClick}></div>
            <div className="popup-container">
                <div className="popup-close-btn" onClick={onCloseClick} title="close">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="800px"
                        height="800px"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M19 5L4.99998 19M5.00001 5L19 19"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="login-popup-wrap">
                    <div className="login-popup-left">
                        <div className="popup-form">
                            <div className="pro-cta-title">
                                <img
                                    src="/icons/diamond-popup.svg"
                                    width={44}
                                    height={44}
                                    alt="fontcert pro"
                                />
                                <h4>Upgrade to Pro for Unlimited Access</h4>
                            </div>
                            <ul className="pro-cta-fetaures">
                                <li>Get access to entire collection</li>
                                <li>Explore unlimited fonts in-uses</li>
                                <li className='soon'>Get access to font pairing</li>
                                <li className='soon'>Showcase your type works</li>
                            </ul>
                            <div className="pro-cta-plans">
                                <PaywallButton entitlement="PRO" term={0} isAuth={isAuth} className="lifetime-plan">
                                    <div className="pro-cta-plan-top">
                                        <h5>Believer</h5>
                                        <span><strong>$50</strong>/lifetime</span>
                                    </div>
                                    <p>
                                        <img src='/icons/icon-clock-popup.svg' alt="pro" width={13} height={8} />
                                        <span>Only for the first 1000 members</span>
                                    </p>
                                </PaywallButton>
                                <PaywallButton entitlement="PRO" term={1} isAuth={isAuth} className="monthly-plan">
                                    <div className="pro-cta-plan-top">
                                        <h5>Monthly</h5>
                                        <span><strong>$10</strong>/month</span>
                                    </div>
                                    <p>Billed every month</p>
                                </PaywallButton>
                            </div>
                        </div>
                    </div>
                    <div
                        className="login-popup-right"
                        style={{ backgroundImage: 'url("/images/pro-banner.png")' }}
                    >
                    </div>
                    <div className="popup-message">
                        {stripeError !== "" && <div className="message-text error">{stripeError} <span onClick={() => { setStripeError("") }}>x</span></div>}
                    </div>
                </div>
            </div>
        </div>
    );
};