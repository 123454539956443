// app/components/SupportPopup.tsx
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isAuthenticated } from "~/features/AuthHelper";
import { subscribeStripePlan } from "~/features/stripeFunctions";
import PopupCarousel from "./PopupCarousel";

interface Props {
    onCloseClick: () => void;
}

export const SupportPopup: React.FC<Props> = ({ onCloseClick }) => {
    const [stripeError, setStripeError] = useState("");
    const [type, setType] = useState("lifetime");
    const [isAuth, setIsAuth] = useState(false);

    const authenticated = isAuthenticated();
    useEffect(() => {
        setIsAuth(authenticated);
    }, [isAuthenticated()]);

    const dispatch = useDispatch();

    return (
        <div className={"popup-wrap login-popup support-popup"}>
            <div className="login-pop-close" onClick={onCloseClick}></div>
            <div className="popup-container">
                <div className="popup-close-btn" onClick={onCloseClick} title="close">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="800px"
                        height="800px"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M19 5L4.99998 19M5.00001 5L19 19"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="login-popup-wrap">
                    <div className="login-popup-left">
                        <div className="popup-form">
                            <div className="pro-cta-title">
                                <h4 className="support-title">Become a supporter</h4>
                            </div>
                            <div className="pro-cta-description">
                                <p>Unlock Pro features and help us
                                    build the future of the Fontcert</p>
                            </div>
                            <ul className="pro-cta-fetaures">
                                <li>Get access to entire collection</li>
                                <li>Explore unlimited fonts in-uses</li>
                                <li className='soon'>Get access to font pairing</li>
                                <li className='soon'>Showcase your type works</li>
                            </ul>
                            <div className="pro-cta-plans">
                                {type === 'lifetime' ? (
                                    <button type="button"
                                        onClick={() => subscribeStripePlan({
                                            entitlement: 'PRO', term: 0
                                        }, isAuth, dispatch)}
                                    >
                                        <div className="pro-cta-plan-top">
                                            <h5>Lifetime</h5>
                                            <span><strong>$50</strong>/lifetime</span>
                                        </div>
                                        <p className="sub-text">
                                            Support once
                                        </p>
                                    </button>
                                ) : (

                                    <button type="button" onClick={() => subscribeStripePlan({
                                        entitlement: 'PRO', term: 1
                                    }, isAuth, dispatch)}>
                                        <div className="pro-cta-plan-top">
                                            <h5>Monthly</h5>
                                            <span><strong>$10</strong>/month</span>
                                        </div>
                                        <p className="sub-text">Support every month</p>
                                    </button>
                                )}

                            </div>
                            <div className="pro-cta-bottom">
                                {type === 'lifetime' ? (
                                    <button type="button" onClick={() => setType("monthly")}>Show monthly</button>
                                ) : (
                                    <button type="button" onClick={() => setType("lifetime")}>Show lifetime</button>
                                )}
                                {/* <span className="why-support">Why support Fontcert?</span> */}
                            </div>
                        </div>
                    </div>
                    <div
                        className="login-popup-right">
                        <PopupCarousel />
                    </div>
                    <div className="popup-message">
                        {stripeError !== "" && <div className="message-text error">{stripeError} <span onClick={() => { setStripeError("") }}>x</span></div>}
                    </div>
                </div>
            </div>
        </div>
    );
};
